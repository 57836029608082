// Testimonial.js
import React from 'react';

const Testimonial = () => {
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <h2 className="text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Reviews from our users 🎓
        </h2>

        <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-8">
          <blockquote className="rounded-lg bg-white p-6 shadow-xl sm:p-8">
            <div>
              <p className="text-lg font-medium text-gray-900">Bryce B.</p>
              <p className="text-xs font-medium text-gray-600">Georgia Tech</p>

              <div className="flex gap-0.5 text-purple-600 mt-2">
                {/* 5 Star SVGs */}
                {[...Array(5)].map((_, i) => (
                  <svg key={i} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
              </div>
            </div>
            <p className="mt-4 text-gray-700">
              I've used StudyBot on nearly every Canvas quiz and it works well.
            </p>
          </blockquote>

          <blockquote className="rounded-lg bg-white p-6 shadow-xl sm:p-8">
            <div>
              <p className="text-lg font-medium text-gray-900">Antoinette G.</p>
              <p className="text-xs font-medium text-gray-600">Ohio State University</p>

              <div className="flex gap-0.5 text-purple-600 mt-2">
                {/* 5 Star SVGs */}
                {[...Array(5)].map((_, i) => (
                  <svg key={i} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
              </div>
            </div>
            <p className="mt-4 text-gray-700">
              Works soo much better than another extension I tried.
            </p>
          </blockquote>

          <blockquote className="rounded-lg bg-white p-6 shadow-xl sm:p-8">
            <div>
              <p className="text-lg font-medium text-gray-900">Allen R.</p>
              <p className="text-xs font-medium text-gray-600">NYU</p>

              <div className="flex gap-0.5 text-purple-600 mt-2">
                {/* 5 Star SVGs */}
                {[...Array(5)].map((_, i) => (
                  <svg key={i} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
              </div>
            </div>
            <p className="mt-4 text-gray-700">
              I use it on my open book exams. It's honestly so helpful.
            </p>
          </blockquote>
          

          {/* Repeat the above blockquote for each testimonial, adjusting the name and text as necessary. */}

        </div>
      </div>
    </section>
  );
};

export default Testimonial;
