// src/pages/Privacy.js

import React from 'react';

const Privacy = () => {
  return (
    <div style={{ padding: '20px', backgroundColor: '#ffffff', color: '#000000' }}>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>Privacy Policy</h1>
      <p>This privacy policy ("Policy") describes how StudyBot Education ("Application," "we," "us," or "our") collects, uses, and shares personal information of users ("User," "you," or "your").</p>

      <h2 style={{ fontSize: '20px', fontWeight: '600', marginTop: '24px' }}>Personal Information We Collect</h2>
      <p>StudyBot Education does not collect any personal information from users, except for the email address used to sign up for StudyBot Education services. No other personal information is collected from our users.</p>

      <h2 style={{ fontSize: '20px', fontWeight: '600', marginTop: '24px' }}>How We Collect Personal Information</h2>
      <p>We only collect your email address through our registration process when you sign up for StudyBot Education services.</p>

      <h2 style={{ fontSize: '20px', fontWeight: '600', marginTop: '24px' }}>Why We Collect Personal Information</h2>
      <p>We collect your email address to provide you with access to StudyBot Education services and to communicate important updates or information about our services. We do not use your email address for any other purpose or share it with third parties.</p>

      <h2 style={{ fontSize: '20px', fontWeight: '600', marginTop: '24px' }}>How We Use Personal Information</h2>
      <p>We use your email address to provide you with access to StudyBot Education services and to communicate important updates or information about our services. We do not use your email address for any other purpose or share it with third parties.</p>

      <h2 style={{ fontSize: '20px', fontWeight: '600', marginTop: '24px' }}>Sharing Personal Information with Third Parties</h2>
      <p>We do not share your personal information with third parties.</p>

      <h2 style={{ fontSize: '20px', fontWeight: '600', marginTop: '24px' }}>How We Protect Personal Information</h2>
      <p>StudyBot Education ensures the security of your personal information. Your email address is securely stored and only accessible by authorized StudyBot Education personnel.</p>

      <h2 style={{ fontSize: '20px', fontWeight: '600', marginTop: '24px' }}>Compliance with Applicable Data Protection Laws and Regulations</h2>
      <p>As StudyBot Education operates in various jurisdictions, we are committed to compliance with applicable data protection laws and regulations in each jurisdiction. We are dedicated to following best practices and will update our policies as needed to ensure compliance.</p>
    </div>
  );
};

export default Privacy;
