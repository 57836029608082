import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ExtensionImage from '../icons/extensionimage.png'; // Ensure this is the correct path to your image

const Tooltip = ({ onClose, onConfirm }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg relative max-w-sm mx-auto">
        <button className="absolute top-0 right-0 text-2xl font-semibold p-4" onClick={onClose}>×</button>
        <div className="text-center">
          <div className="text-6xl mb-4">😢</div>
          <h2 className="text-2xl font-bold mb-4">We hate to see you go!</h2>
          <p className="mb-8">Are you sure you'd like to cancel?</p>
          <div className="flex justify-center">
            <button className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-10 rounded mr-2" onClick={onClose}>No</button>
            <button className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-10 rounded ml-2" onClick={onConfirm}>Yes</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MessagePopup = ({ content, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg relative max-w-sm mx-auto">
      <button className="absolute top-0 right-0 text-2xl font-semibold p-4" onClick={onClose}>×</button>
      <div className="text-center">
        <div className="text-6xl mb-4">🎉</div> {/* You can replace this emoji with any relevant icon or emoji */}
        <h2 className="text-2xl font-bold mb-4">Subscription Status</h2>
        {/* Since we are directly injecting HTML content, the structure for the message itself is defined outside of this component */}
        <div className="mb-8" dangerouslySetInnerHTML={{ __html: content }}></div>
        <div className="flex justify-center">
          <button className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-10 rounded" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  </div>
);

const Profile = () => {
  const [activeTab, setActiveTab] = useState('billing');
  const [showTooltip, setShowTooltip] = useState(false);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [messagePopupContent, setMessagePopupContent] = useState('');

  const handleCancelClick = () => {
    setShowTooltip(true);
  };

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const handleTooltipConfirm = async () => {
    const response = await fetch('https://api.studybot.education/cancel-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    const data = await response.json();
    if (response.ok) {
      console.log('Subscription cancelled successfully:', data);
      setMessagePopupContent('Your subscription was cancelled successfully');
    } else {
      console.error('Failed to cancel subscription:', data.error);
      setMessagePopupContent(`Sorry! There was an issue, try again or contact us at <a href="mailto:support@studybot.education" class="underline text-blue-600">support@studybot.education</a>.`);
    }
    setShowTooltip(false);
    setShowMessagePopup(true);
  };

  return (
    <div className="bg-gray-100 text-gray-800 min-h-screen">
      <nav className="bg-white shadow">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between">
            <div className="flex space-x-4">
              <div>
                <Link to="/" className="flex items-center py-5 px-2 text-gray-700 hover:text-gray-900">
                  <span className="font-bold text-xl">StudyBot</span>
                </Link>
              </div>
              <div className="hidden md:flex items-center space-x-1">
                <button onClick={() => setActiveTab('billing')} className={`py-5 px-3 ${activeTab === 'billing' ? 'text-gray-900 font-bold border-b-4 border-purple-600' : 'text-gray-700 hover:text-gray-900'}`}>Billing</button>
                <button onClick={() => setActiveTab('extension')} className={`py-5 px-3 ${activeTab === 'extension' ? 'text-gray-900 font-bold border-b-4 border-purple-600' : 'text-gray-700 hover:text-gray-900'}`}>Extension</button>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="max-w-4xl mx-auto py-8 px-4">
        <div className="bg-white p-6 rounded-lg shadow">
          {activeTab === 'billing' && (
            <div>
              <h2 className="text-2xl font-bold mb-2 text-gray-800">Billing</h2>
              <div className="mt-4 flex space-x-4">
                <button onClick={handleCancelClick} className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out">
                  Cancel Subscription
                </button>
                <a href="mailto:support@studybot.education" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out">
                  Contact Us
                </a>
              </div>
            </div>
          )}
          {activeTab === 'extension' && (
            <div>
              <div className="mt-8 text-center">
                <h1 className="text-4xl font-semibold mb-4 text-black">Ready to get the answers?</h1>
                <p className="text-lg mb-8 text-black">Install StudyBot from the Chrome Web Store</p>
                <div className="mb-8">
                  <img src={ExtensionImage} alt="Extension Preview" className="rounded-lg shadow-md max-w-full h-auto" />
                </div>
                <a href="https://chromewebstore.google.com/detail/studybot-answers-in-one-c/iampndcdjdkiohilkdpipiabmceaanba?hl=en-US&utm_source=ext_sidebar" target="_blank" rel="noopener noreferrer">
                  <button className="bg-gradient-to-r from-purple-400 to-purple-800 text-white font-bold py-3 px-20 rounded">
                    Get the Extension
                  </button>
                </a>
              </div>
            </div>
          )}
          {showTooltip && <Tooltip onClose={handleTooltipClose} onConfirm={handleTooltipConfirm} />}
          {showMessagePopup && <MessagePopup content={messagePopupContent} onClose={() => setShowMessagePopup(false)} />}
        </div>
      </div>
    </div>
  );
};

export default Profile;
