// src/pages/SuccessPage.js
import React from 'react';
import { Link } from 'react-router-dom';

const SuccessPage = () => {
  return (
    <div className="success-page">
      <h1>Payment Successful!</h1>
      <p>Your subscription has been activated.</p>
      <Link to="/">Go to Homepage</Link>
    </div>
  );
};

export default SuccessPage;
