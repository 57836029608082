import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import awsConfig from '../awsConfig';
import Testimonial from '../icons/testimonial.png'; // Make sure this path is correct

const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.UserPoolId,
  ClientId: awsConfig.ClientId,
});

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to access query parameters
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get('source'); // Extract the 'source' parameter

  const handleSubmit = (event) => {
    event.preventDefault();

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        console.log('login success', result);
        const idToken = result.getIdToken().getJwtToken();
        const accessToken = result.getAccessToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken(); // Capture the refresh token
      
        // Store the access token in localStorage for later use
        localStorage.setItem('accessToken', accessToken);

        // Assuming EXTENSION_IDS are the IDs of your Chrome extensions
        const EXTENSION_IDS = ["iampndcdjdkiohilkdpipiabmceaanba", "hljimgmhecadpoamdfejaehgkjeigomf"];

        // Prepare the message payload to include the refresh token
        const message = { type: "AUTH_SUCCESS", idToken, accessToken, refreshToken };

        // Attempt to send tokens to each extension
        console.log("Attempting to send message to extensions:", message);
        EXTENSION_IDS.forEach((id) => {
          try {
            window.chrome.runtime.sendMessage(id, message, (response) => {
              console.log(`Response from extension ${id}:`, response);
            });
          } catch (error) {
            console.error(`Error sending message to extension ${id}:`, error);
          }
        });

        // Send tokens to your server for storage/update, including the refresh token
        fetch('https://api.studybot.education/update-tokens', { 
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email, 
            idToken: idToken,
            accessToken: accessToken,
            refreshToken: refreshToken,
          }),
        })
        .then(response => response.json())
        .then(data => console.log('Server response:', data))
        .catch(error => console.error('Error sending tokens to server:', error));

        
        // Redirect based on the source
        if (source === 'extension') {
          navigate('/extsuccess'); // Redirect to /extension if logged in via extension
        } else {
          navigate('/profile'); // Otherwise, redirect to the profile page
        }
      },
      
      onFailure: (err) => {
        console.error('Login failed', err);
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  const handleForgotPassword = () => {
    const cognitoUser = new CognitoUser({
      Username: forgotPasswordEmail,
      Pool: userPool,
    });

    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        console.log('Forgot password success', data);
        alert('Password reset code sent to your email.');
        setIsCodeSent(true);
      },
      onFailure: (err) => {
        console.error('Forgot password failed', err);
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  const handleVerifyCode = () => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    const cognitoUser = new CognitoUser({
      Username: forgotPasswordEmail,
      Pool: userPool,
    });

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        console.log('Password reset success');
        alert('Password has been reset successfully.');
        setIsForgotPassword(false);
        setIsCodeSent(false);
        setIsCodeVerified(false);
      },
      onFailure: (err) => {
        console.error('Password reset failed', err);
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  return (
    <div className="flex min-h-screen">
      <div className="flex-1 flex flex-col justify-center px-6 lg:px-20 xl:px-24 bg-white relative">
        <div className="w-full max-w-lg">
          <h2 className="text-4xl font-extrabold text-gray-900">StudyBot AI</h2>
          <h3 className="mt-6 text-3xl font-extrabold text-gray-900">Log In</h3>
          <p className="mt-2 text-sm text-gray-600">
            New here? <a href="/register" className="underline text-purple-600">Create an account</a>
          </p>
          <div className="mt-8">
            <form onSubmit={handleSubmit} className="space-y-6">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                className="w-full px-5 py-3 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-purple-500 focus:ring-1 focus:ring-purple-500 text-black"
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
                className="w-full px-5 py-3 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-purple-500 focus:ring-1 focus:ring-purple-500 text-black"
              />
              <button
                type="submit"
                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Log In
              </button>
            </form>
            <div className="mt-4 flex justify-between items-center">
              <button
                onClick={() => setIsForgotPassword(true)}
                className="underline text-purple-600"
              >
                Forgot Password?
              </button>
              <a
                href="mailto:support@studybot.education"
                className="text-purple-600 underline"
              >
                Contact Us
              </a>
            </div>
            {isForgotPassword && (
              <div className="mt-4">
                <input
                  type="email"
                  value={forgotPasswordEmail}
                  onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                  className="w-full px-5 py-3 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-purple-500 focus:ring-1 focus:ring-purple-500 text-black"
                />
                {!isCodeSent && (
                  <button
                    onClick={handleForgotPassword}
                    className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-2"
                  >
                    Send Password Reset Email
                  </button>
                )}
                {isCodeSent && !isCodeVerified && (
                  <>
                    <input
                      type="text"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      placeholder="Enter verification code"
                      required
                      className="w-full px-5 py-3 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-purple-500 focus:ring-1 focus:ring-purple-500 text-black mt-2"
                    />
                    <button
                      onClick={() => setIsCodeVerified(true)}
                      className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-2"
                    >
                      Submit Code
                    </button>
                  </>
                )}
                {isCodeVerified && (
                  <>
                    <input
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="Enter new password"
                      required
                      className="w-full px-5 py-3 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-purple-500 focus:ring-1 focus:ring-purple-500 text-black mt-2"
                    />
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm new password"
                      required
                      className="w-full px-5 py-3 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-purple-500 focus:ring-1 focus:ring-purple-500 text-black mt-2"
                    />
                    <button
                      onClick={handleVerifyCode}
                      className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-2"
                    >
                      Submit New Password
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center bg-purple-700 p-12">
        <div className="max-w-md w-full text-center">
          <h2 className="text-5xl font-bold text-white mb-20">School On Easy Mode</h2>
          <img src={Testimonial} alt="Testimonial" className="w-full h-auto object-cover rounded-lg"/>
        </div>
      </div>
    </div>
  );
};

export default Login;
