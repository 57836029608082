/* global fbq */
/* global ttq */


// src/pages/ExtensionPage.js
import React, { useEffect } from 'react';
import ExtensionImage from '../icons/extensionimage.png'; // Ensure this is the correct path to your image

const ExtensionPage = () => {
  useEffect(() => {
    const purchaseAmount = 10; // This value should be dynamically set based on the actual purchase
    const currency = 'USD'; // This should also be dynamically set based on the actual purchase
    
    // Facebook Pixel Purchase Event
    window.fbq('track', 'Purchase', {
      value: purchaseAmount,
      currency: currency,
    });

    // TikTok Pixel PlaceAnOrder Event
    if (window.ttq) {
      window.ttq.track('PlaceAnOrder', {
        contents: [
          {
            content_id: "101",
            content_type: "product",
            content_name: "StudyBot Unlimited",
            quantity: 1 // Assuming a quantity of 1 for the purchase
          }
        ],
        value: purchaseAmount,
        currency: currency,
      });
    }
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-2xl mx-auto">
        <h1 className="text-4xl font-semibold mb-4 text-black">Ready to get the answers?</h1>
        <p className="text-lg mb-8 text-black">Install StudyBot from the Chrome Web Store</p>
        <div className="mb-8">
          <img src={ExtensionImage} alt="Extension Preview" className="rounded-lg shadow-md max-w-full h-auto" />
        </div>
        <a href="https://chromewebstore.google.com/detail/studybot-answers-in-one-c/iampndcdjdkiohilkdpipiabmceaanba?hl=en-US&utm_source=ext_sidebar" target="_blank" rel="noopener noreferrer">
          <button className="bg-gradient-to-r from-purple-400 to-purple-800 text-white font-bold py-3 px-20 rounded">
            Get the Extension
          </button>
        </a>
      </div>
    </div>
  );
};

export default ExtensionPage;
