/* global fbq */
/* global ttq */



import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import GradCap from '../icons/GradCap';
import Check from '../icons/Check';
import Logo from '../icons/sbedulogo.png';
import HeroVid from '../icons/herovid.mp4';
import Tut1 from '../icons/tutv1.mp4';
import Tut2 from '../icons/tutv2.mp4';
import Tut3 from '../icons/tutv3.mp4';
import Testimonial from '../components/Testimonial';
import Rating from '../components/Rating';
import { StarIcon } from '@heroicons/react/solid';



import { Link as ScrollLink } from 'react-scroll';
import Platforms from '../icons/platforms.png';

// Define the getDayAfterTwoDays function
function getDayAfterTwoDays() {
  const today = new Date();
  today.setDate(today.getDate() + 2);
  const day = today.getDate();
  const month = today.toLocaleString('default', { month: 'long' });

  let suffix = 'th';
  if (day === 1 || day === 21 || day === 31) {
    suffix = 'st';
  } else if (day === 2 || day === 22) {
    suffix = 'nd';
  } else if (day === 3 || day === 23) {
    suffix = 'rd';
  }

  return `${month} ${day}${suffix}`;
}

const LandingPage = () => {
  useEffect(() => {
    if (typeof fbq === 'function') {
      fbq('track', 'PageView');
    }
    if (window.ttq) {
      window.ttq.track('ViewContent', {
        contents: [
          {
            content_id: "101",
            content_type: "product",
            content_name: "StudyBot"
          }
        ],
        value: 0,
        currency: "USD"
      });
    }
  }, []);

  // Call the function to get the offer end date
  const offerEndDate = getDayAfterTwoDays();

  return (
    <div className="relative bg-white">
      <div className="hidden md:flex bg-white text-black py-2 px-8 justify-between items-center">
        <div className="text-lg font-bold">
          <img src={Logo} alt="Logo" style={{ height: '100px' }} />
        </div>
        <div className="flex items-center">
          <Link to="/login" className="text-black mr-8 py-1 sm:px-4 rounded-full hidden sm:block hover:bg-gray-100 font-bold">Login</Link>
          <ScrollLink to="pricing-section" smooth={true} duration={500} className="btn text-white bg-purple-600 hover:bg-purple-500 focus:outline-none px-4 py-2 cursor-pointer">Get Started</ScrollLink>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 lg:px-0">
        {/* Hero Section for Desktop */}
        <div className="hero min-h-[5vh] bg-white pt-3 sm:pt-10 hidden md:block">
          <div className="flex flex-col lg:flex-row-reverse justify-center items-center">
            <div className="w-full lg:max-w-xl overflow-hidden rounded-lg shadow-2xl ml-16">
              <video 
                src={HeroVid} 
                className="w-full h-auto"
                autoPlay 
                loop 
                muted 
                playsInline 
              />
            </div>
            <div className="text-left lg:mr-10 mt-8 lg:mt-0">
              {/* Rating Component */}
              <div className="inline-flex items-center mb-4 bg-purple-50 rounded-full px-4 py-2 shadow-sm relative">
                {[...Array(5)].map((_, index) => (
                  <StarIcon key={index} className="h-5 w-5 text-yellow-400" />
                ))}
                <span className="ml-2 text-sm font-medium text-black">Rated 4.9/5.0</span>
                <div className="absolute -right-24 top-1/2 transform -translate-y-1/2 flex items-center">
                  <div className="relative mr-2">
                    <div className="w-2 h-2 bg-green-500 rounded-full animate-ping"></div>
                    <div className="w-2 h-2 bg-green-500 rounded-full absolute top-0"></div>
                  </div>
                  <span className="text-xs font-medium text-green-500">AI Updated</span>
                </div>
              </div>
              <h1 className="text-6xl lg:text-7xl font-bold leading-none">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-300 to-purple-800">Answers</span>
                <span className="text-black"> In</span>
              </h1>
              <h1 className="text-6xl lg:text-7xl font-bold text-black leading-none">One Click</h1>
              <p className="py-5 text-black text-xl">Get instant answers to <span className="font-bold">tough</span> questions.</p>
              <ScrollLink to="pricing-section" smooth={true} duration={500} className="btn text-white bg-gradient-to-r from-purple-500 to-purple-800 hover:from-purple-500 hover:to-purple-700 focus:outline-none w-48 py-2 cursor-pointer">Get Answers</ScrollLink>
            </div>
          </div>
        </div>

        {/* Hero Section for Mobile */}
        <div className="hero min-h-[5vh] bg-white pt-3 sm:pt-10 block md:hidden">
          <div className="flex flex-col justify-between items-center">
            <div className="text-left mt-8">
              {/* Rating Component */}
              <div className="inline-flex items-center mb-4 bg-purple-50 rounded-full px-4 py-2 shadow-sm">
                {[...Array(5)].map((_, index) => (
                  <StarIcon key={index} className="h-5 w-5 text-yellow-400" />
                ))}
                <span className="ml-2 text-sm font-medium text-black">Rated 4.9/5.0</span>
              </div>
              <h1 className="text-6xl font-bold leading-none">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-300 to-purple-800">Answers</span>
                <span className="text-black"> In</span>
              </h1>
              <h1 className="text-6xl font-bold text-black leading-none">One Click</h1>
              <p className="mt-4 text-black text-xl">Get instant answers to <span className="font-bold">tough</span> questions.</p>
            </div>
            <div className="w-full overflow-hidden rounded-lg shadow-2xl mt-8">
              <video 
                src={HeroVid} 
                className="w-full h-auto"
                autoPlay 
                loop 
                muted 
                playsInline 
              />
            </div>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 py-1 mt-4">
              <div className="text-sm grid grid-cols-2 gap-4 py-4 font-bold">
                <div className="flex w-full max-w-xs items-center space-x-2">
                  <GradCap className="h-6 w-6 text-purple-600 flex-shrink-0" />
                  <span className="text-black text-left">100% Undetectable</span>
                </div>
                <div className="flex w-full max-w-xs items-center space-x-2">
                  <GradCap className="h-6 w-6 text-purple-600 flex-shrink-0" />
                  <span className="text-black text-left">Instant Results</span>
                </div>
                <div className="flex w-full max-w-xs items-center space-x-2">
                  <GradCap className="h-6 w-6 text-purple-600 flex-shrink-0" />
                  <span className="text-black text-left">8M+ Answered Questions</span>
                </div>
                <div className="flex w-full max-w-xs items-center space-x-2">
                  <GradCap className="h-6 w-6 text-purple-600 flex-shrink-0" />
                  <span className="text-black text-left">Advanced Academic AI</span>
                </div>
              </div>
            </div>
            <ScrollLink to="pricing-section" smooth={true} duration={500} className="btn text-white bg-gradient-to-r from-purple-500 to-purple-800 hover:from-purple-500 hover:to-purple-700 focus:outline-none w-full py-2 cursor-pointer my-4">Get Answers</ScrollLink>
          </div>
        </div>
        

        {/* Bullet Points Section */}
        <div className="max-w-6xl mx-auto px-4 sm:px-6 py-2 hidden md:block">
          <div className="flex font-bold flex-col items-center sm:flex-row sm:justify-center sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 py-8">
            <div className="flex w-full max-w-xs items-center space-x-2">
              <GradCap className="h-6 w-6 text-purple-600" />
              <span className="text-black text-left">100% Undetectable</span>
            </div>
            <div className="flex w-full max-w-xs items-center space-x-2">
              <GradCap className="h-6 w-6 text-purple-600" />
              <span className="text-black text-left">Instant Results</span>
            </div>
            <div className="flex w-full max-w-xs items-center space-x-2">
              <GradCap className="h-6 w-6 text-purple-600" />
              <span className="text-black text-left">8M+ Answered Questions</span>
            </div>
            <div className="flex w-full max-w-xs items-center space-x-2">
              <GradCap className="h-6 w-6 text-purple-600" />
              <span className="text-black text-left">Most Advanced Academic AI</span>
            </div>
          </div>
        </div>
      </div>


      {/* Tutorial Title Section */}
      <div className="hero bg-gray-50 pt-10 sm:pt-20">
        <div className="max-w-7xl mx-auto px-4 lg:px-0">
        <h2 className="text-3xl sm:text-5xl font-bold text-black mb-4 sm:mb-8">
          Watch StudyBot <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-800">Cook</span> 🧑‍🍳
        </h2>        
        </div>
      </div>


      {/* Tutorial Section */}
      <div className="hero bg-gray-50 pt-1 sm:pt-10 py-5 pb-10">
        <div className="max-w-7xl mx-auto px-4 lg:px-0">
          <div className="flex flex-col lg:flex-row-reverse justify-center items-center lg:space-x-10">
            <div className="w-full lg:w-1/3 overflow-hidden rounded-lg shadow-2xl order-2 lg:order-none"> {/* Adjusted class here */}
              <video 
                src={Tut1} 
                className="w-full h-auto"
                autoPlay 
                loop 
                muted 
                playsInline 
              />
            </div>
            <div className="text-left lg:w-1/2 mt-8 lg:mt-0 order-1">
            <h1 className="text-2xl sm:text-4xl font-bold text-black pb-1 sm:pb-0 mb-4 sm:mb-0">
              1. <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-800">Highlight</span> Your Question
            </h1>
              
              <p className="hidden sm:block py-5 text-black text-xl">Simply highlight a question you want answered.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Step2 Tutorial */}
      <div className="hero bg-white pb-10 pt-3 sm:pt-10 py-5">
        <div className="max-w-7xl mx-auto px-4 lg:px-0">
          <div className="flex flex-col lg:flex-row justify-center items-center lg:space-x-10">
            <div className="w-full lg:w-1/3 overflow-hidden rounded-lg shadow-2xl order-2 lg:order-none"> {/* Adjusted class here */}
              <video 
                src={Tut2} // Make sure to change the video source
                className="w-full h-auto"
                autoPlay 
                loop 
                muted 
                playsInline 
              />
            </div>
            <div className="text-right lg:w-1/2 mt-8 lg:mt-0 order-1 lg:order-2">
            <h1 className="text-2xl sm:text-4xl font-bold text-black pb-1 sm:pb-0 mb-4 sm:mb-0">
              2. Click <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-800">'Ask StudyBot'</span>
            </h1>
              
              <p className="hidden sm:block py-5 text-black text-xl">Ask StudyBot for an instant answer to your question.</p>
            </div>
          </div>
        </div>
      </div>


      <div className="hero bg-gray-50 pt-1 sm:pt-10 py-5 pb-10">
        <div className="max-w-7xl mx-auto px-4 lg:px-0">
          <div className="flex flex-col lg:flex-row-reverse justify-center items-center lg:space-x-10">
            <div className="w-full lg:w-1/3 overflow-hidden rounded-lg shadow-2xl order-2 lg:order-none"> {/* Adjusted class here */}
              <video 
                src={Tut3} 
                className="w-full h-auto"
                autoPlay 
                loop 
                muted 
                playsInline 
              />
            </div>
            <div className="text-left lg:w-1/2 mt-8 lg:mt-0 order-1">
            <h1 className="text-2xl sm:text-4xl font-bold text-black pb-1 sm:pb-0 mb-4 sm:mb-0">
              3. Remain <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-800">100% Incognito</span>
            </h1>
              
              <p className="hidden sm:block py-5 text-black text-xl">We value privacy. StudyBot remains in the background and is not detectable.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto px-4 sm:px-6 bg-white pb-10 sm:pb-10">
        {/* Title Section */}
        <div className="pt-10 sm:pt-20">
          <div className="text-center">
            <h2 className="text-4xl sm:text-5xl font-bold text-black mb-8">How Smart Is StudyBot? 🧠</h2>
          
          </div>
        </div>

        {/* Stats Section */}
        <div className="py-5">
          <div className="stats shadow flex flex-col sm:flex-row justify-center space-x-0 sm:space-x-4 space-y-4 sm:space-y-0 bg-white mx-auto" style={{ maxWidth: '768px' }}>
            <div className="stat bg-white text-black">
              <div className="stat-title text-black">SAT Reading & Writing</div>
              <div className="stat-value bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-800">710/800</div>
              <div className="stat-desc text-black">Top 7%</div>
            </div>
            
            <div className="stat bg-white text-black">
              <div className="stat-title text-black">Uniform Bar Exam</div>
              <div className="stat-value bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-800">298/400</div>
              <div className="stat-desc text-black">Top 10%</div>
            </div>
            
            <div className="stat bg-white text-black">
              <div className="stat-title text-black">GRE Verbal Exam</div>
              <div className="stat-value bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-800">165/170</div>
              <div className="stat-desc text-black">Top 4%</div>
            </div>
          </div>
        </div>
      </div>



      {/* Tutorial Title Section */}
      <div className="hero bg-gray-50 pt-10 sm:pt-20">
        <div className="max-w-7xl mx-auto px-4 lg:px-0">
        <h2 className="text-4xl sm:text-5xl font-bold text-black mb-4 sm:mb-8">
          StudyBot <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-800">Pricing</span> 
        </h2>        
        </div>
      </div>


      {/* Pricing Section */}
      <section id="pricing-section" className=" bg-gray-50">
        <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="ring-2 ring-purple-600 rounded-3xl p-8 xl:p-10 shadow-2xl bg-white"> {/* Added bg-white here */}
            <div className="flex items-center justify-between gap-x-4 flex-wrap"> {/* Added flex-wrap */}
              <h2 className="text-black text-2xl sm:text-3xl font-bold leading-8">StudyBot Unlimited</h2> {/* Adjusted text size */}
              <p className="rounded-full bg-purple-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-purple-600 whitespace-nowrap">3-Days Free!</p> {/* Added whitespace-nowrap */}
            </div>
            <p className="mt-6 flex items-baseline gap-x-1">
              <span className="text-5xl font-bold tracking-tight text-gray-900">$0</span>
              <span className="ml-2 line-through text-gray-500 text-2xl">$4.99/week</span>
            </p>
            <div className="mt-4 text-sm font-semibold text-gray-600 text-decoration-line: underline">
              Offer valid until <span className="text-black">{offerEndDate}!</span>
            </div>


            <ul role="list" className="mt-6 space-y-3 text-sm leading-6 text-black xl:mt-6">
              <li className="flex gap-x-3 text-base font-bold">
                <Check className="h-6 w-5 flex-none text-purple-600" /> <span>Unlimited Solutions</span>
              </li>
              <li className="flex gap-x-3 text-base font-bold">
                <Check className="h-6 w-5 flex-none text-purple-600" /> <span>Most Advanced AI</span>
              </li>
              <li className="flex gap-x-3 text-base font-bold">
                <Check className="h-6 w-5 flex-none text-purple-600" /> <span>Instant Answers</span>
              </li>
              <li className="flex gap-x-3 text-base font-bold">
                <Check className="h-6 w-5 flex-none text-purple-600" /> <span>Incognito & Undetectable</span>
              </li>
            </ul>

            <Link to="/register" className="bg-gradient-to-r from-purple-400 to-purple-800 text-white shadow-sm hover:from-purple-500 hover:to-purple-700 mt-6 block rounded-md py-2 px-3 text-center text-base font-medium leading-6 transition duration-300 ease-in-out" aria-describedby="tier-premium">
              Start 3-Day Free Trial
            </Link>
            
            <div className="text-center mt-6 font-bold text-black md:underline">
              <span>Pay $0 Today. Cancel Anytime.</span>
            </div>
          </div>
          <div className="text-center mt-6 pt-5 pb-5">
            <p className="font-bold text-black pb-3 text-2xl">Works on <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-800">every</span> popular platform</p>
            <img src={Platforms} alt="Platforms" style={{ width: '220px', height: 'auto', margin: '0 auto' }} />
          </div>
        </div>
      </section>


      <Testimonial /> {/* This is where you include the Testimonial component */}



      {/* FAQs */}
      <div className="text-5xl font-semibold mb-8 text-black max-w-2xl mx-auto px-4 pt-20 sm:px-6 text-center">Frequently Asked Questions</div>
      <div className="collapse text-black max-w-2xl mx-auto px-4 sm:px-6 py-1 border border-purple-500 shadow-md mb-4">
        <input type="checkbox" /> 
        <div className="collapse-title text-xl font-medium">
          How do I use StudyBot?
        </div>
        <div className="collapse-content"> 
          <p className="font-bold">Simply highlight text on any page, and click "Ask StudyBot"</p>
        </div>
      </div>
      <div className="collapse text-black bg-white max-w-2xl mx-auto px-4 sm:px-6 py-1 border border-purple-500 shadow-md mb-4">
        <input type="checkbox" /> 
        <div className="collapse-title text-xl font-medium">
          Is StudyBot detectable?
        </div>
        <div className="collapse-content"> 
          <p className="font-bold">Absolutely not. StudyBot works completely in the background. Not even AI detection software is able to see you are using StudyBot.</p>
        </div>
      </div>
      <div className="collapse text-black bg-white max-w-2xl mx-auto px-4 sm:px-6 py-1 border border-purple-500 shadow-md mb-20">
        <input type="checkbox" /> 
        <div className="collapse-title text-xl font-medium">
          What if I need to cancel?
        </div>
        <div className="collapse-content"> 
          <p className="font-bold">We offer simple subscription cancellations. Visit your profile and click "Cancel Subscription"</p>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 text-white text-center py-4">
        &copy; 2023 StudyBot. All rights reserved.
        <div>
          <Link to="/privacy" className="text-white hover:underline">Privacy Policy</Link>
          {/* Adding the Contact Us link */}
          <a href="mailto:support@studybot.education" className="text-white hover:underline ml-4">Contact Us</a>
        </div>
      </footer>

    </div>
  );
};

export default LandingPage;