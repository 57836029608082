import React from 'react';

const ExtSuccess = () => {
    return (
      <div className="min-h-screen bg-gray-100 flex justify-center items-center">
        <div className="bg-white p-10 rounded-lg shadow-xl text-center max-w-lg mx-auto">
          <h1 className="text-4xl font-bold mb-2 text-gray-800">You're All Set!</h1>
          <h2 className="text-2xl mb-5 text-gray-700">Here's how to use StudyBot</h2>
          <div className="text-left text-lg text-gray-600">
            <p className="mb-2"><span className="font-semibold">1.</span> Highlight text</p>
            <p className="mb-2"><span className="font-semibold">2.</span> Click "Ask StudyBot"</p>
            <p className="mb-2"><span className="font-semibold">3.</span> Get instant solutions!</p>
          </div>
        </div>
      </div>
    );
};

export default ExtSuccess;
